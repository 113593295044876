import React from "react"
import rehypeReact from "rehype-react"
import styled from "@emotion/styled"

import { default as Content } from "./ContentBase"
import { Headline } from "./Typography"

const Headlines = styled.div`
  margin: 0 auto;
  ${p =>
    p.theme.mq({
      width: ["100%", "100%", "83%", "83%"],
    })}
`

const StyledHeadline = styled(Headline)`
  ${p =>
    p.theme.mq({
      marginBottom: ["12%", "8%", "6%", "4%"],
    })}
`

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    h2: StyledHeadline,
  },
}).Compiler

const HeadlineList = ({ htmlAst }) => {
  return (
    <Content>
      <Headlines>{renderAst(htmlAst)}</Headlines>
    </Content>
  )
}

export { HeadlineList }
