import React, { useState } from "react"
import { Formik } from "formik"
import * as Yup from "yup"

import { postToSlack } from "./form-utils"
import { Form, Button, Fieldset } from "./FormElements"

const ContactUsForm = () => {
  const [sent, setSent] = useState(false)
  const [error, setError] = useState(false)

  return (
    <div>
      <Formik
        initialValues={{
          _honeypot: "",
          Fullname: "",
          Email: "",
          Company: "",
          StarWars_vs_StarTrek: "",
          Message: "",
        }}
        validationSchema={Yup.object().shape({
          _honeypot: Yup.string().length(0),
          Fullname: Yup.string().required("Required"),
          Email: Yup.string()
            .email("Invalid email address")
            .required("Required"),
          Message: Yup.string().max(300),
        })}
        onSubmit={values => {
          postToSlack(values).then(res => {
            if (res.status >= 400) {
              console.error("Bad response from server")
              setError(true)
            } else {
              setSent(true)
            }
          })
        }}
        render={({ isSubmitting }) => (
          <Form>
            <fieldset disabled={sent}>
              <Fieldset type="hidden" name="_honeypot" />
              <Fieldset name="Fullname" type="text" placeholder="Full Name" />
              <Fieldset name="Email" type="email" placeholder="Email" />
              <Fieldset name="Company" type="text" placeholder="Company" />
              <Fieldset
                name="StarWars_vs_StarTrek"
                type="text"
                placeholder="Star Wars or Star Trek"
              />
              <Fieldset
                name="Message"
                component="textarea"
                placeholder="Message"
              />
              <Button
                type="submit"
                arrow={false}
                disabled={isSubmitting}
                sent={sent}
                error={error}
              >
                {sent ? "Sent!" : error ? "Error :(" : "Send"}
              </Button>
            </fieldset>
          </Form>
        )}
      />
    </div>
  )
}

export { ContactUsForm }
