import React from "react"
import rehypeReact from "rehype-react"
import styled from "@emotion/styled"

import { default as Content } from "./ContentBase"
import star from "../images/star.svg"
import { FadeIn } from "./FadeIn"

const Title = styled.h2`
  color: ${p => p.theme.colors.primary};
  font-family: ${p => p.theme.fonts.copy};
  font-size: 16px;
  font-weight: lighter;
  line-height: 1.88;
  text-transform: uppercase;
  letter-spacing: 2.6px;
`

const Text = styled.p`
  color: rgba(26, 11, 64, 0.45);
  font-family: ${p => p.theme.fonts.copy};
  font-size: 14px;
  line-height: 1.68;
`

const StepInner = styled.div`
  opacity: ${p => (p.inView ? 1 : 0)};
  transform: translateY(${p => (p.inView ? 0 : -50)}px);
  transition: all 500ms 200ms;
  position: relative;
  ${p =>
    p.theme.mq({
      width: ["100%", "100%", "50%"],
      marginBottom: ["30px", "30px", 0],
    })}
  &:before {
    content: url(${star});
    background: white;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    ${p =>
      p.theme.mq({
        top: ["auto", "auto", 0],
        bottom: [0, 0, "auto"],
        padding: [0, 0, "4px 0 37px"],
      })}
  }

  &:nth-of-type(even) {
    ${p =>
      p.theme.mq({
        borderLeft: [0, 0, "2px solid #30119b"],
        padding: ["0 0 32px", "0 0 32px", "0 0 0 7.8%"],
        marginLeft: ["0", "0", "calc(50% - 2px)"],
      })}
    &:before {
      ${p =>
        p.theme.mq({
          left: ["50%", "50%", "-1px"],
        })}
    }
  }

  &:nth-of-type(odd) {
    ${p =>
      p.theme.mq({
        borderRight: [0, 0, "2px solid #30119b"],
        padding: ["0 0 32px", "0 0 32px", "0 7.8% 0 0"],
        marginRight: ["0", "0", "calc(50% - 2px)"],
      })}
    &:before {
      ${p =>
        p.theme.mq({
          left: ["50%", "50%", "calc(100% + 1px)"],
        })}
    }
  }

  &:nth-last-of-type(2) {
    margin-bottom: 30px;
    ${p =>
      p.theme.mq({
        paddingBottom: ["32px", "32px", "3%"],
      })}
  }

  &:first-of-type,
  &:last-of-type {
    background: #fff;
    border: 0;
    margin: 0 auto;
    padding: 0;
    ${p =>
      p.theme.mq({
        width: ["100%", "100%", "66%"],
      })}
    ${Title} {
      text-align: center;
    }
  }

  &:first-of-type {
    margin-bottom: 30px;
    ${p =>
      p.theme.mq({
        paddingBottom: ["32px", "32px", "3%"],
      })}
    &:before {
      ${p =>
        p.theme.mq({
          display: ["block", "block", "none"],
        })}
    }
  }

  &:last-of-type {
    &:before {
      display: none;
    }
  }
`

const Line = styled.span`
  background: #30119b;
  display: block;
  width: 2px;
  height: 98px;
  margin: 0 auto 30px;
  &:last-of-type {
    display: none;
  }
  ${p =>
    p.theme.mq({
      display: ["block", "block", "none"],
    })}
`

const Step = ({ children }) => {
  return (
    <FadeIn>
      {({ inview, mobileTablet }) => (
        <>
          <StepInner {...inview}>{children}</StepInner>
          {mobileTablet && <Line />}
        </>
      )}
    </FadeIn>
  )
}

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    h3: Title,
    p: Text,
    step: Step,
  },
}).Compiler

const Process = ({ htmlAst }) => {
  return <Content>{renderAst(htmlAst)}</Content>
}

export { Process }
