// self-managed route list

const primaryNavItems = [
  {
    to: "/our-work",
    text: "Our Work",
  },
  {
    to: "/services",
    text: "Services",
  },
  {
    to: "/process",
    text: "Process",
  },
  {
    to: "/about",
    text: "About",
  },
  {
    to: "/media",
    text: "Media",
  },
  {
    to: "/faq",
    text: "FAQ",
  },
]

const secondaryNavItems = [
  {
    to: "/careers",
    text: "Careers",
  },
]
const secondaryNavItemsForReals = [
  {
    to: "/blog",
    text: "Blog",
  },
  {
    to: "/careers",
    text: "Careers",
  },
  {
    to: "/case-studies",
    text: "Case Studies",
  },
  {
    to: "/media",
    text: "Media",
  },
  {
    to: "/privacy",
    text: "Privacy",
  },
]

export { primaryNavItems, secondaryNavItems, secondaryNavItemsForReals }
