import { css } from "@emotion/core"
import facepaint from "facepaint"

import IBMPlexSansBoldWOFF from "./fonts/IBM-Plex-Sans/woff/IBMPlexSans-Bold.woff"
import IBMPlexSansBoldWOFF2 from "./fonts/IBM-Plex-Sans/woff2/IBMPlexSans-Bold.woff2"
import IBMPlexSansLightWOFF from "./fonts/IBM-Plex-Sans/woff/IBMPlexSans-Light.woff"
import IBMPlexSansLightWOFF2 from "./fonts/IBM-Plex-Sans/woff2/IBMPlexSans-Light.woff2"
import IBMPlexSansItalicWOFF from "./fonts/IBM-Plex-Sans/woff/IBMPlexSans-Italic.woff"
import IBMPlexSansItalicWOFF2 from "./fonts/IBM-Plex-Sans/woff2/IBMPlexSans-Italic.woff2"
import IBMPlexSansRegularWOFF from "./fonts/IBM-Plex-Sans/woff/IBMPlexSans-Regular.woff"
import IBMPlexSansRegularWOFF2 from "./fonts/IBM-Plex-Sans/woff2/IBMPlexSans-Regular.woff2"

import IBMPlexSerifBoldWOFF from "./fonts/IBM-Plex-Serif/woff/IBMPlexSerif-Bold.woff"
import IBMPlexSerifBoldWOFF2 from "./fonts/IBM-Plex-Serif/woff2/IBMPlexSerif-Bold.woff2"

const mq = facepaint([
  "@media(min-width: 420px)",
  "@media(min-width: 920px)",
  "@media(min-width: 1120px)",
])

const theme = {
  mq,
  content: {
    maxWidth: 1228,
  },
  nav: {
    height: 75,
    mode: "light",
  },
  colors: {
    accent: "#637381", // battlefield-gray
    primary: "#9013fe", // violet
    text: "#1a0b40", // dark-indigo
  },
  fonts: {
    headline: `"IBMPlexSerif"`,
    copy: `"IBMPlexSans"`,
  },
}

const styles = css`
  @font-face {
    font-family: "IBMPlexSans";
    font-weight: bold;
    font-style: normal;
    src: url(${IBMPlexSansBoldWOFF2}) format("woff2"),
      url(${IBMPlexSansBoldWOFF}) format("woff");
    -webkit-font-smoothing: auto;
  }
  @font-face {
    font-family: "IBMPlexSans";
    font-weight: lighter;
    font-style: normal;
    src: url(${IBMPlexSansLightWOFF2}) format("woff2"),
      url(${IBMPlexSansLightWOFF}) format("woff");
    -webkit-font-smoothing: auto;
  }
  @font-face {
    font-family: "IBMPlexSans";
    font-weight: normal;
    font-style: italic;
    src: url(${IBMPlexSansItalicWOFF2}) format("woff2"),
      url(${IBMPlexSansItalicWOFF}) format("woff");
    -webkit-font-smoothing: auto;
  }
  @font-face {
    font-family: "IBMPlexSans";
    font-weight: normal;
    font-style: normal;
    src: url(${IBMPlexSansRegularWOFF2}) format("woff2"),
      url(${IBMPlexSansRegularWOFF}) format("woff");
    -webkit-font-smoothing: auto;
  }
  @font-face {
    font-family: "IBMPlexSerif";
    font-weight: bold;
    font-style: normal;
    src: url(${IBMPlexSerifBoldWOFF2}) format("woff2"),
      url(${IBMPlexSerifBoldWOFF}) format("woff");
    -webkit-font-smoothing: auto;
  }

  html {
    font-family: ${theme.fonts.copy};
    font-size: 16px;
  }

  body {
    color: ${theme.colors.text};
    font-family: ${theme.fonts.copy};
  }
`

export { styles, theme }
