import React from "react"
import rehypeReact from "rehype-react"
import styled from "@emotion/styled"

import ContentBase from "./ContentBase"
import { ButtonLink } from "./Button"

const Content = styled(ContentBase)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const Columns = styled.div`
  width: 100%;
  & > div {
    display: flex;
    justify-content: center;
    ${p =>
      p.theme.mq({
        display: ["block", "block", "flex", "flex"],
      })}
  }
`

const Column = styled.p`
  margin-left: 1.5%;
  margin-right: 1.5%;
  width: 40%;
  ${p =>
    p.theme.mq({
      fontSize: ["12px", "12px", "14px", "16px"],
      width: ["100%", "100%", "40%", "40%"],
    })}
`
const ColumnDiv = styled(Column)`
  text-align: left;
`.withComponent("div")

const StyledButtonLink = styled(ButtonLink)`
  margin-top: 5.5%;
`

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    p: Column,
    column: ColumnDiv,
  },
}).Compiler

const FullTwoColumn = ({ htmlAst, link, href }) => {
  return (
    <Content>
      <Columns>{renderAst(htmlAst)}</Columns>
      {!!link && !!href && (
        <StyledButtonLink to={href}>{link}</StyledButtonLink>
      )}
    </Content>
  )
}

export {
  FullTwoColumn,
  Columns as FullTwoColumnColumns,
  Content as FullTwoColumnContent,
}
