import axios from "axios"

const postToSlack = values => {
  return axios
    .post(
      "https://hooks.slack.com/services/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",
      {
        text: Object.entries(values).reduce((acc, [key, value]) => {
          if (value) {
            return acc + `*${key}*: ${value}\n`
          }
          return acc
        }, ""),
      },
      {
        headers: {
          "Content-Type": "text/plain;charset=utf-8",
        },
      }
    )
    .catch(err => {
      console.error(err)
    })
}

export { postToSlack }
