import { useMediaPredicate } from "react-media-hook"

const View = ({ children, size }) => {
  const sizes = {
    sm: useMediaPredicate("(max-width: 419px)"),
    md: useMediaPredicate("(min-width: 420px) and (max-width: 919px)"),
    lg: useMediaPredicate("(min-width: 920px) and (max-width: 1119px)"),
    xl: useMediaPredicate("(min-width: 1120px)"),
  }
  const view = sizes.md ? "md" : sizes.lg ? "lg" : sizes.xl ? "xl" : "sm"

  if (size) {
    if (!(Array.isArray(size) && size.some(s => sizes[s])) || sizes[size]) {
      return null
    }
  }

  return typeof children === "function" ? children({ view }) : children
}

export { View }
