import styled from "@emotion/styled"

const getSize = () => ({ size = "" }) => {
  switch (size) {
    case "xxxxs":
      return 0.5
    case "xxxs":
      return 1
    case "xxs":
      return 2
    case "xs":
      return 3
    case "s":
    case "sm":
      return 6
    case "m":
    case "md":
      return 8
    case "l":
    case "lg":
      return 12
    case "xl":
      return 16
    case "xxl":
      return 24
    case "xxxl":
      return 32
    case "xxxxl":
      return 48
    default:
      return 0
  }
}

const VerticalSpacing = styled.div`
  margin-bottom: ${getSize}%;
`

const MenuSpacer = styled.div`
  padding-top: ${p => p.theme.nav.height}px;
`

export default VerticalSpacing

export { MenuSpacer }
