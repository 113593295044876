import React from "react"
import rehypeReact from "rehype-react"
import styled from "@emotion/styled"

import ContentBase from "./ContentBase"
import { ButtonLink } from "./Button"
import { Headline as HeadlineBase, Subhead as SubheadBase } from "./Typography"

const Content = styled(ContentBase)`
  text-align: center;
`

const Headline = styled(HeadlineBase)`
  ${p =>
    p.theme.mq({
      width: ["100%", "100%", "66%", "66%"],
    })}
`

const Subhead = styled(SubheadBase)`
  ${p =>
    p.theme.mq({
      width: ["100%", "100%", "66%", "66%"],
    })}
`

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    h2: Headline,
    p: Subhead,
  },
}).Compiler

const FullHeading = ({ htmlAst, link, href }) => {
  return (
    <Content>
      {renderAst(htmlAst)}
      {!!link && !!href && <ButtonLink to={href}>{link}</ButtonLink>}
    </Content>
  )
}

export { FullHeading }
