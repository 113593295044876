import React, { useState } from "react"
import { Formik } from "formik"
import * as Yup from "yup"

import { default as Content } from "../ContentBase"

import { postToSlack } from "./form-utils"
import { Form, Button, Fieldset, FileUpload } from "./FormElements"

const JobPostingForm = () => {
  const [sent, setSent] = useState(false)
  const [error, setError] = useState(false)
  const [slackAttachment, setSlackAttachment] = useState("")

  return (
    <Content id="apply">
      <Formik
        initialValues={{
          _honeypot: "",
          Email: "",
          Resume: "",
        }}
        validationSchema={Yup.object().shape({
          _honeypot: Yup.string().length(0),
          Email: Yup.string()
            .email("Invalid email address")
            .required("Required"),
          Resume: Yup.mixed().required("Required"),
        })}
        onSubmit={values => {
          postToSlack({ ...values, Resume: slackAttachment }).then(res => {
            if (!res || res.status >= 400) {
              console.error("Bad response from server")
              setError(true)
            } else {
              setSent(true)
            }
          })
        }}
        render={({ isSubmitting }) => (
          <Form>
            <fieldset disabled={sent}>
              <Fieldset type="hidden" name="_honeypot" />
              <Fieldset name="Email" type="email" placeholder="Email" />
              <Fieldset
                field={FileUpload}
                name="Resume"
                type="file"
                placeholder="Resume"
                accepts=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                onUpload={setSlackAttachment}
              />
              <Button
                type="submit"
                arrow={false}
                disabled={isSubmitting}
                sent={sent}
                error={error}
              >
                {sent ? "Sent!" : error ? "Error :(" : "Send"}
              </Button>
            </fieldset>
          </Form>
        )}
      />
    </Content>
  )
}

export { JobPostingForm }
