import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

import ContentBase from "./ContentBase"
import Hamburger from "./Hamburger"
import { LayoutContext } from "./Layout"

import { View } from "../utils"
import logo from "../images/crucial_logo.svg"
import { primaryNavItems } from "../routes"

const Container = styled.header`
  position: absolute;
  width: 100%;
  z-index: 1;
  box-shadow: ${p =>
    p.theme.nav.mode === "light" ? "0 2px 0 0 rgba(26, 11, 64, 0.05)" : "none"};
`

const Content = styled(ContentBase)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${p => p.theme.nav.height}px;
`

const Logo = styled.img`
  margin: 0;
  vertical-align: middle;
`

const Nav = styled.nav`
  width: 53.5%;
`

const List = styled.ul`
  display: flex;
  justify-content: space-between;
  margin: 0;
`

const ListItem = styled.li`
  margin: 0;
  list-style: none;
`

const getLinkColor = () => p => {
  if (p.currentSlug === p.to) {
    return p.theme.colors.primary
  }
  if (p.theme.nav.mode === "dark") {
    return "#fff"
  }
  return "rgba(26, 11, 64, 0.5)"
}

const StyledLink = styled(Link, {
  shouldForwardProp: prop => prop !== "currentSlug",
})`
  font-family: ${p => p.theme.fonts.copy};
  font-size: 14px;
  text-transform: uppercase;
  text-decoration: none;
  color: ${getLinkColor};
  ${p =>
    p.theme.nav.mode === "dark" &&
    css`
      text-shadow: 1px 1px 0 #000;
    `}
  &:hover {
    text-decoration: underline;
  }
`

const Header = ({ siteTitle }) => {
  return (
    <LayoutContext.Consumer>
      {({ slug: currentSlug }) => (
        <Container>
          <Content>
            <Link to="/">
              <Logo src={logo} />
            </Link>
            <View size={["sm", "md"]}>
              <Hamburger open={false} />
            </View>
            <View size={["lg", "xl"]}>
              <Nav>
                <List>
                  {primaryNavItems.map(({ to, text }) => (
                    <ListItem key={to}>
                      <StyledLink to={to} currentSlug={currentSlug}>
                        {text}
                      </StyledLink>
                    </ListItem>
                  ))}
                </List>
              </Nav>
            </View>
          </Content>
        </Container>
      )}
    </LayoutContext.Consumer>
  )
}

export default Header
