import React, { useState } from "react"
import rehypeReact from "rehype-react"
import styled from "@emotion/styled"
import { Collapse } from "react-collapse"

import { default as Content } from "./ContentBase"
import { Headline } from "./Typography"

const TitleBar = styled.button`
  appearance: none;
  outline: none;
  background: none;
  border: 0;
  cursor: pointer;
  padding: 28px 18px 28px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  width: 100%;
  & h3 {
    color: ${p => (p.open ? p.theme.colors.text : "rgba(26, 11, 64, 0.5)")};
    transition: 300ms color;
  }
`

const Title = styled(Headline)`
  margin: 0;
  ${p =>
    p.theme.mq({
      fontSize: ["16px", "16px", "18px", "22px"],
    })}
`.withComponent("h3")

const Paragraph = styled.p`
  font-family: ${p => p.theme.fonts.copy};
  line-height: 1.5;
  margin: 0;
  padding-bottom: 1.45rem;
  ${p =>
    p.theme.mq({
      width: ["100%", "100%", "80%", "80%"],
    })}
`

const ToggleIcon = styled.span`
  color: ${p => p.theme.colors.primary};
  font-size: 40px;
  font-family: ${p => p.theme.fonts.copy};
  margin-left: 40px;
`

const Wrapper = styled.div`
  width: 83%;
  margin: 0 auto;
  border-bottom: solid 2px rgba(26, 11, 64, 0.35);
  ${p =>
    p.theme.mq({
      width: ["100%", "100%", "83%", "83%"],
    })}
`

const AccordionItem = ({ expanded, children }) => {

  const [title, ...rest] = children
  const [open, setOpen] = useState(Boolean(expanded) || false)

  return (
    <Wrapper>
      <TitleBar open={open} onClick={() => setOpen(!open)}>
        {title}
        <ToggleIcon>{open ? `\u2013` : "+"}</ToggleIcon>
      </TitleBar>
      <Collapse isOpened={open}>{rest}</Collapse>
    </Wrapper>
  )
}

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    accordionitem: AccordionItem,
    h3: Title,
    p: Paragraph,
  },
}).Compiler

const Accordion = ({ htmlAst }) => {
  return <Content>{renderAst(htmlAst)}</Content>
}

export { Accordion }
