import React, { useState } from "react"
import ReactModalVideo from "react-modal-video"
import Button from "./Button"

import "react-modal-video/css/modal-video.min.css"

const ModalVideo = ({ channel, videoid, buttontext }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <ReactModalVideo
        channel={channel}
        isOpen={isOpen}
        videoId={videoid}
        onClose={() => setIsOpen(false)}
      />
      <Button onClick={() => setIsOpen(true)}>{buttontext}</Button>
    </>
  )
}

export { ModalVideo }
