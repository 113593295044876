import React from "react"
import styled from "@emotion/styled"

import ContentBase from "./ContentBase"
import { ButtonLink } from "./Button"
import { Headline } from "./Typography"

const Content = styled(ContentBase)`
  overflow: hidden;
`

const Logos = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 -65px;
  ${p =>
    p.theme.mq({
      flexDirection: ["column", "column", "row", "row"],
      flexWrap: ["nowrap", "nowrap", "wrap"],
      margin: ["0", "0", "0", "0 -65px"],
    })}
`

const Heading = styled(Headline)`
  border-bottom: solid 2px rgba(26, 11, 64, 0.35);
  margin-bottom: 6%;
`

const Image = styled.img`
  height: 40px;
  margin: 0 65px 6%;
  ${p =>
    p.theme.mq({
      marginBottom: ["10%", "10%", "6%", "6%"],
    })}
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const LogoList = ({ images, heading, href, link }) => {
  return (
    <Content>
      <Heading>{heading}</Heading>
      <Logos>
        {images.map(({ id, publicURL }, index) => (
          <Image key={id + index} src={publicURL} alt="" />
        ))}
      </Logos>
      {!!link && !!href && (
        <ButtonWrapper>
          <ButtonLink to={href}>{link}</ButtonLink>
        </ButtonWrapper>
      )}
    </Content>
  )
}

export { LogoList }
