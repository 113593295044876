import styled from "@emotion/styled"
import { Form } from "formik"
import { ButtonWrapper } from "./Button"

const StyledForm = styled(Form)`
  margin: 0 auto;
  ${p =>
    p.theme.mq({
      width: ["100%", "100%", "66%", "66%"],
    })}

  & label {
    display: block;
    font-family: ${p => p.theme.fonts.headline};
    line-height: 1.29;
    margin-top: 3.5%;
    width: 100%;
    &:first-of-type {
      margin-top: 0;
    }
    ${p =>
      p.theme.mq({
        fontSize: ["18px", "18px", "23px", "28px"],
        marginTop: ["10.5%", "10.5%", "3.5%", "3.5%"],
      })}
  }

  & input,
  textarea {
    appearance: none;
    border: 2px solid ${p => p.theme.colors.text};
    display: block;
    padding: 12px;
    margin-bottom: 12px;
    width: 100%;
  }

  & fieldset {
    border: 0;
  }

  & ${ButtonWrapper} {
    margin-top: 0 !important;
  }
`

export { StyledForm as Form }
