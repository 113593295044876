import React, { useState } from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { Global } from "@emotion/core"
import { ThemeProvider } from "emotion-theming"
import deepmerge from "deepmerge"

import "./layout.css"
import { theme as crucialTheme, styles } from "../../theme"
import { View } from "../../utils"

import Header from "../Header"
import Footer from "../Footer"
import FlyoutNav from "../FlyoutNav"
import Contact from "../Contact"

const LayoutContext = React.createContext({})

const Container = styled.div`
  overflow: ${p => (p.open ? "hidden" : "visible")};
`

const Layout = ({ children, theme: themeOverrides = {}, slug = "" }) => {
  const theme = deepmerge(crucialTheme, themeOverrides)
  const [dockOpen, setDockOpen] = useState(false)

  const contextValue = {
    dockOpen,
    setDockOpen,
    slug: slug.slice(0, -1),
  }

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <LayoutContext.Provider value={contextValue}>
          <ThemeProvider theme={theme}>
            <Container open={dockOpen}>
              <Global styles={styles} />
              <Header siteTitle={data.site.siteMetadata.title} />
              <main>
                {children}
                <Contact />
              </main>
              <Footer />
              <View size={["sm", "md"]}>
                <FlyoutNav />
              </View>
            </Container>
          </ThemeProvider>
        </LayoutContext.Provider>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
export { LayoutContext }
