import React from "react"
import rehypeReact from "rehype-react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import Img from "gatsby-image"

import ContentBase from "./ContentBase"
import { ButtonLink } from "./Button"
import { Headline, Subhead } from "./Typography"

const Content = styled(ContentBase)`
  display: flex;
  ${p =>
    p.theme.mq({
      paddingTop: ["6%", "6%", 0],
      display: ["block", "block", "flex"],
    })}
  ${p =>
    p.border &&
    css`
      border-bottom: 1px solid #ccc;
    `}
`

const Icon = styled.div`
  text-align: center;
  ${p =>
    p.theme.mq({
      margin: ["0 auto", "0 auto", "0 10% 0 4%"],
      width: ["25%", "25%", "10%"],
    })}
`

const Text = styled.div`
  ${p =>
    p.theme.mq({
      margin: ["0", "0", "0 2% 0 0"],
      width: ["100%", "100%", "74%"],
    })}
`

const List = styled.ul`
  ${p =>
    p.theme.mq({
      fontSize: ["16px", "16px", "20px", "24px"],
    })}
  margin-bottom: 50px;
`

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    h2: Headline,
    p: Subhead,
    ul: List,
  },
}).Compiler

const FullWidthIconHeading = ({ htmlAst, image, link, href, border }) => {
  return (
    <Content border={border}>
      <Icon>
        {image.publicURL.endsWith(".svg") ? (
          <img src={image.publicURL} alt="" />
        ) : (
          <Img fluid={image.childImageSharp.fluid} />
        )}
      </Icon>
      <Text>
        {renderAst(htmlAst)}
        {!!link && !!href && <ButtonLink to={href}>{link}</ButtonLink>}
      </Text>
    </Content>
  )
}

export { FullWidthIconHeading }
