import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"

import logo from "../images/crucial_logo.svg"
import { primaryNavItems, secondaryNavItems } from "../routes"

import ContentBase from "./ContentBase"
import Social from "./Social"

const Container = styled.footer`
  background: ${p => p.theme.colors.text};
  padding: 6% 0;
  ${p =>
    p.theme.mq({
      padding: ["10% 0", "20% 0", "6% 0", "6% 0"],
    })}
`

const Content = styled(ContentBase)`
  display: flex;
  justify-content: space-between;
  ${p =>
    p.theme.mq({
      alignItems: ["center", "center", "flex-start", "flex-start"],
      flexDirection: ["column", "column", "row", "row"],
    })}
`

const Left = styled.div`
  display: flex;
  flex: 1;
  ${p =>
    p.theme.mq({
      flexDirection: ["column", "column", "row", "row"],
      marginBottom: ["5%", "5%", "0", "0"],
    })}
`

const Logo = styled.img`
  margin: 0;
  ${p =>
    p.theme.mq({
      width: ["100%", "100%", "51%", "51%"],
      marginBottom: ["20%", "20%", "0", "0"],
    })}
`

const LogoLink = styled(Link)`
  ${p =>
    p.theme.mq({
      width: ["116px", "!16px", "33%", "33%"],
    })}
`

const Links = styled.div`
  display: flex;
  ${p =>
    p.theme.mq({
      width: ["100%", "100%", "66%", "66%"],
      flexDirection: ["column", "column", "row", "row"],
    })}
`

const List = styled.ul`
  margin: 0;
  list-style: none;
  ${p =>
    p.theme.mq({
      width: ["100%", "100%", "50%", "50%"],
      textAlign: ["center", "center", "inherit", "inherit"],
    })}
`

const ListItem = styled.li`
  ${p =>
    p.theme.mq({
      marginBottom: ["24px", "24px", "18px", "18px"],
    })}
`

const StyledLink = styled(Link)`
  color: #fff;
  font-family: ${p => p.theme.fonts.copy};
  text-decoration: none;
  text-transform: uppercase;
  &:hover {
    text-decoration: underline;
  }
  ${p =>
    p.theme.mq({
      fontSize: ["16px", "16px", "14px", "14px"],
    })}
`

const Footer = () => {
  return (
    <Container>
      <Content>
        <Left>
          <LogoLink to="/">
            <Logo src={logo} />
          </LogoLink>
          <Links>
            <List>
              {primaryNavItems.map(({ to, text }) => (
                <ListItem key={to}>
                  <StyledLink to={to}>{text}</StyledLink>
                </ListItem>
              ))}
            </List>
            <List>
              {secondaryNavItems.map(({ to, text }) => (
                <ListItem key={to}>
                  <StyledLink to={to}>{text}</StyledLink>
                </ListItem>
              ))}
            </List>
          </Links>
        </Left>
        <Social
          twitter="https://twitter.com/hydrateio"
          facebook="https://www.facebook.com/hydrateio/"
          linkedin="https://www.linkedin.com/company/hydrate/"
          github="https://github.com/hydrateio"
        />
      </Content>
    </Container>
  )
}

export default Footer
