import styled from "@emotion/styled"

const Headline = styled.h2`
  font-family: ${p => p.theme.fonts.headline};
  line-height: 1.52;
  margin-left: auto;
  margin-right: auto;
  ${p =>
    p.theme.mq({
      fontSize: ["18px", "18px", "23px", "28px"],
    })}
`

export { Headline }
