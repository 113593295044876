import styled from "@emotion/styled"

const Subhead = styled.p`
  font-family: ${p => p.theme.fonts.copy};
  line-height: 1.42;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  ${p =>
    p.theme.mq({
      fontSize: ["16px", "16px", "20px", "24px"],
    })}
`

export { Subhead }
