import styled from "@emotion/styled"

const ContentBase = styled.div`
  max-width: ${p => p.theme.content.maxWidth}px;
  margin: 0 auto;
  ${p =>
    p.theme.mq({
      paddingLeft: ["32px", "32px", "40px", "40px"],
      paddingRight: ["32px", "32px", "40px", "40px"],
    })}
`

export default ContentBase
