import React from "react"
import { InView } from "react-intersection-observer"

import { View } from "../utils"

const FadeIn = ({ children }) => {
  return (
    <View>
      {({ view }) => {
        const mobileTablet = /sm|md/.test(view)
        return (
          <InView threshold={mobileTablet ? 0.25 : 1} triggerOnce>
            {inview => children({ inview, mobileTablet })}
          </InView>
        )
      }}
    </View>
  )
}

export { FadeIn }
