import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import FocusTrap from "focus-trap-react"

import { LayoutContext } from "./Layout"
import Hamburger from "./Hamburger"

import logo from "../images/crucial_logo.svg"
import { primaryNavItems } from "../routes"

const Panel = styled.div`
  background: ${p => p.theme.colors.text};
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100vw;
  overflow: auto;
  transform: translateX(${p => (p.open ? "0" : "-100%")});
  transition: transform 300ms;
  padding: 0 33px;
  -webkit-overflow-scrolling: touch;
  z-index: 1;
`

const Logo = styled.img`
  margin: 0;
  vertical-align: middle;
`

const LogoBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 130px;
`

const List = styled.ul`
  margin: 0;
`

const ListItem = styled.li`
  display: block;
  margin: 0 0 50px;
  list-style: none;
`

const StyledLink = styled(Link, {
  shouldForwardProp: prop => prop !== "currentSlug",
})`
  color: ${p => (p.currentSlug === p.to ? p.theme.colors.primary : "#fff")};
  font-family: ${p => p.theme.fonts.copy};
  font-size: 20px;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 3.8px;
  &:hover {
    text-decoration: underline;
  }
`

const FlyoutNav = () => {
  return (
    <LayoutContext.Consumer>
      {({ dockOpen, slug: currentSlug }) => (
        <FocusTrap active={dockOpen}>
          <Panel open={dockOpen}>
            <LogoBar>
              <Link to="/">
                <Logo src={logo} />
              </Link>
              <Hamburger open={true} />
            </LogoBar>
            <nav>
              <List>
                {primaryNavItems.map(({ to, text }) => (
                  <ListItem key={to}>
                    <StyledLink to={to} currentSlug={currentSlug}>
                      {text}
                    </StyledLink>
                  </ListItem>
                ))}
              </List>
            </nav>
          </Panel>
        </FocusTrap>
      )}
    </LayoutContext.Consumer>
  )
}

export default FlyoutNav
