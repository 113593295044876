import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

import { LayoutContext } from "./Layout"

const Button = styled.button`
  appearance: none;
  background: none;
  border: 0;
  height: 20px;
  width: 28px;
  display: block;
  position: relative;
  & > span {
    background: ${p =>
      p.theme.nav.mode === "dark" || p.open ? "#fff" : "rgba(26, 11, 64, 0.5)"};
    position: absolute;
    height: 3px;
    width: 100%;
    left: 0;
    top: 50%;
    &:first-of-type {
      transform: translateY(-10px);
      ${p =>
        p.open &&
        css`
          transform: rotate(45deg);
        `}
    }
    &:nth-of-type(2) {
      transform: translateY(-50%);
      ${p => p.open && "opacity: 0"}
    }
    &:last-of-type {
      transform: translateY(7px);
      ${p =>
        p.open &&
        css`
          transform: rotate(-45deg);
        `}
    }
  }
`

const Hamburger = ({ open }) => {
  return (
    <LayoutContext.Consumer>
      {({ dockOpen, setDockOpen }) => (
        <Button open={open} onClick={() => setDockOpen(!dockOpen)}>
          <span />
          <span />
          <span />
        </Button>
      )}
    </LayoutContext.Consumer>
  )
}

export default Hamburger
