import React from "react"
import { Field, ErrorMessage } from "formik"

// By combining a vanilla <label> plus Formik's <Field> and <ErrorMessage>,
// we can abstract a generic "Fieldset" component for most of our inputs.
const Fieldset = ({ field, name, label, ...rest }) => (
  <React.Fragment>
    {!!label && <label htmlFor={name}>{label}</label>}
    {!!field ? (
      React.createElement(field, { id: name, name, ...rest })
    ) : (
      <Field id={name} name={name} {...rest} />
    )}
    <ErrorMessage name={name} />
  </React.Fragment>
)

export { Fieldset }
