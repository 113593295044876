import React from "react"
import styled from "@emotion/styled"

import TwitterIcon from "./TwitterIcon"
import LinkedInIcon from "./LinkedInIcon"
import FacebookIcon from "./FacebookIcon"
import GithubIcon from "./GithubIcon"

const Links = styled.div`
  display: flex;
  justify-content: space-between;
  ${p =>
    p.theme.mq({
      margin: ["0 -8px", "0 -8px", "0 -10px", "0 -10px"],
    })}
`

const SocialLink = styled.a`
  ${p =>
    p.theme.mq({
      height: ["18px", "18px", "26px", "26px"],
      width: ["18px", "18px", "26px", "26px"],
      margin: ["0 16px", "0 16px", "0 10px", "0 10px"],
    })}
  & > svg {
    width: 100%;
    height: 100%;
  }
`

SocialLink.defaultProps = {
  target: "_blank",
}

const Social = ({ type, twitter, linkedin, facebook, github }) => {
  const color = type === "user" ? "rgba(26, 11, 64, 0.35)" : "#fff"
  return (
    <Links>
      {!!twitter && (
        <SocialLink type={type} href={twitter}>
          <TwitterIcon color={color} />
        </SocialLink>
      )}
      {!!linkedin && (
        <SocialLink type={type} href={linkedin}>
          <LinkedInIcon color={color} />
        </SocialLink>
      )}
      {!!facebook && (
        <SocialLink type={type} href={facebook}>
          <FacebookIcon color={color} />
        </SocialLink>
      )}
      {!!github && (
        <SocialLink type={type} href={github}>
          <GithubIcon color={color} />
        </SocialLink>
      )}
    </Links>
  )
}

export default Social
