import React from "react"
import rehypeReact from "rehype-react"
import styled from "@emotion/styled"

import ContentBase from "./ContentBase"
import { ButtonLink } from "./Button"
import Social from "./Social"
import { FadeIn } from "./FadeIn"
import { ModalVideo } from "./ModalVideo"

const Content = styled(ContentBase)`
  text-align: center;
`

const Title = styled.h2`
  color: ${p => p.theme.colors.primary};
  font-family: ${p => p.theme.fonts.copy};
  font-size: 16px;
  font-weight: lighter;
  line-height: 1.88;
  text-transform: uppercase;
  letter-spacing: 2.6px;
`

const Text = styled.p`
  color: rgba(26, 11, 64, 0.45);
  font-family: ${p => p.theme.fonts.copy};
  font-size: 14px;
  line-height: 1.42;
`

const Icons = styled.div`
  & > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -1%;
  }
`

const Item = styled(Icons)`
  margin: 0 1% ${p => (p.fullwidth ? "3%" : "6%")};
  width: ${p => (p.fullwidth ? "100%" : (p.splitwidth ? "48%" : "31%"))};
  ${p =>
    p.theme.mq({
      width: [
        "100%",
        "100%",
        p.fullwidth ? "66%" : (p.splitwidth ? "48%" : "31%"),
  p.fullwidth ? "66%" : (p.splitwidth ? "48%" : "31%"),
      ],
    })}
`

const AnimatedItem = styled(Item)`
  opacity: ${p => (p.inView ? 1 : 0)};
  transform: translateY(${p => (p.inView ? 0 : -50)}px);
  transition: all 500ms 200ms;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const Center = styled.div`
  display: flex;
  justify-content: center;
`

const SocialRehype = props => (
  <Center>
    <Social type="user" {...props} />
  </Center>
)

const IconItem = ({ animate, ...rest }) => {
  if (animate === "true") {
    return (
      <FadeIn>{({ inview }) => <AnimatedItem {...inview} {...rest} />}</FadeIn>
    )
  }
  return <Item {...rest} />
}

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    iconitem: IconItem,
    h3: Title,
    p: Text,
    social: SocialRehype,
    a: ButtonLink,
    modalvideo: ModalVideo,
  },
}).Compiler

const IconList = ({ htmlAst, href, link }) => {
  return (
    <Content>
      <Icons>{renderAst(htmlAst)}</Icons>
      {!!link && !!href && (
        <ButtonWrapper>
          <ButtonLink to={href}>{link}</ButtonLink>
        </ButtonWrapper>
      )}
    </Content>
  )
}

export { IconList }
