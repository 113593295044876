import React from "react"

export default function LinkedInIcon({
  color = "#fff",
  width = "26px",
  height = "26px",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 25"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(-47.000000, -1.000000)"
          fill={color}
          fillRule="nonzero"
        >
          <path
            d="M52.8416602,8.98416016 L52.8416602,25.5717969 L47.3180273,25.5717969 L47.3180273,8.98416016 L52.8416602,8.98416016 Z M53.1931641,3.86224609 C53.204323,4.67684652 52.9225647,5.35752982 52.3478809,5.90431641 C51.773197,6.45110299 51.0171922,6.72449219 50.0798437,6.72449219 L50.0463672,6.72449219 C49.1313366,6.72449219 48.3948596,6.45110299 47.8369141,5.90431641 C47.2789686,5.35752982 47,4.67684652 47,3.86224609 C47,3.03648676 47.2873376,2.35301377 47.8620215,1.81180664 C48.4367053,1.27059951 49.1871308,1 50.1133203,1 C51.0395098,1 51.7815662,1.27059951 52.3395117,1.81180664 C52.8974572,2.35301377 53.1820052,3.03648676 53.1931641,3.86224609 Z M61.3949219,11.3413109 C61.6074842,11.0062934 61.8250799,10.7115688 62.0477148,10.4571289 C62.282052,10.1893151 62.5972864,9.89918776 62.9934277,9.58673828 C63.389569,9.2742888 63.8749743,9.03158615 64.4496582,8.85862305 C65.0243421,8.68565994 65.6631801,8.59917969 66.3661914,8.59917969 C68.274365,8.59917969 69.8086921,9.23243833 70.9692187,10.4989746 C72.1297454,11.7655109 72.71,13.6206518 72.71,16.0644531 L72.71,25.5717969 L67.2031055,25.5717969 L67.2031055,16.7005078 C67.2031055,15.5288223 66.9771409,14.6110157 66.5252051,13.9470605 C66.0732692,13.2831054 65.3674788,12.9511328 64.4078125,12.9511328 C63.7048012,12.9511328 63.1161775,13.1436211 62.6419238,13.5286035 C62.1676702,13.9135859 61.8133801,14.3906222 61.579043,14.9597266 C61.456295,15.2944939 61.3949219,15.7464229 61.3949219,16.3155273 L61.3949219,25.5717969 L55.8880273,25.5717969 C55.9103452,21.1193918 55.9215039,17.5095386 55.9215039,14.7421289 C55.9215039,11.9747192 55.9159245,10.3232253 55.9047656,9.78759766 L55.8880273,8.98416016 L61.3949219,8.98416016 L61.3949219,11.3413109 L61.3949219,11.3413109 Z"
            id=""
          />
        </g>
      </g>
    </svg>
  )
}
