import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import GatsbyImage from "gatsby-image"

import ContentBase from "./ContentBase"

const Content = styled(ContentBase)`
  text-align: center;
`

const ImageCSS = p => css`
  ${p.theme.mq({
    width: ["100%", "80%", "60%", "40%"],
  })}
  margin: 0 auto;
`

const Image = styled.img`
  ${ImageCSS}
`

const Img = styled(GatsbyImage)`
  ${ImageCSS}
`

const FullWidthCenterImage = ({ image }) => {
  return (
    <Content>
      {image.publicURL.endsWith(".svg") ? (
        <Image src={image.publicURL} alt="" />
      ) : (
        <Img fluid={image.childImageSharp.fluid} />
      )}
    </Content>
  )
}

export { FullWidthCenterImage }
