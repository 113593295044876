import React from "react"
import rehypeReact from "rehype-react"
import styled from "@emotion/styled"

import { default as Content } from "./ContentBase"
import { Headline as HeadlineBase, Subhead as SubheadBase } from "./Typography"

const Headline = styled(HeadlineBase)`
  ${p =>
    p.theme.mq({
      width: ["100%", "100%", "66%", "66%"],
    })}
`

const List = styled.ul`
  margin-left: auto;
  margin-right: auto;
  ${p =>
    p.theme.mq({
      width: ["100%", "100%", "80%", "66%"],
    })}
`

const Subhead = styled(SubheadBase)`
  ${p =>
    p.theme.mq({
      width: ["100%", "100%", "66%", "66%"],
    })}
`

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    h2: Headline,
    h3: Headline,
    ul: List,
    p: Subhead,
  },
}).Compiler

const Markdown = ({ htmlAst }) => {
  return <Content>{renderAst(htmlAst)}</Content>
}

export { Markdown }
