import React, { useState } from "react"
import styled from "@emotion/styled"
import { Field } from "formik"
import axios from "axios"

const Background = styled.div`
  position: relative;
  :before {
    content: "";
    background: #9013fe80;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: ${p => p.progress}%;
    transition: width 100ms;
  }
  & > input {
    background: transparent;
    position: relative;
  }
`

const Progress = styled.span`
  position: absolute;
  bottom: 0;
  right: 5px;
  font-size: 10px;
`

const FileUpload = ({ onUpload, ...props }) => {
  const [progress, setProgress] = useState(-1)

  const fileChange = e => {
    if (!e.target.files) {
      return
    }

    let file = e.target.files[0]

    let data = new FormData()
    data.append("file", file)
    data.append("token", process.env.SLACK_OAUTH_TOKEN)
    data.append("channels", "GGQ2MS7B6")

    axios
      .post("https://slack.com/api/files.upload", data, {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: p => {
          setProgress(Math.round((p.loaded * 100) / p.total) - 1)
        },
      })
      .then(({ data }) => {
        if (!data.ok) {
          return Promise.reject(data.error)
        }

        onUpload(data.file.permalink)
        setProgress(100)
      })
      .catch(err => {
        setProgress("error")
        console.error(err)
      })
  }

  return (
    <Field
      {...props}
      render={({ field: { onChange, ...rest } }) => (
        <Background progress={progress}>
          <input
            {...props}
            {...rest}
            onChange={e => {
              fileChange(e)
              onChange(e)
            }}
          />
          {progress > -1 && <Progress>{`${progress}%`}</Progress>}
          {progress === "error" && <Progress>upload error</Progress>}
        </Background>
      )}
    />
  )
}

export { FileUpload }
