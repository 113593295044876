import React from "react"
import rehypeReact from "rehype-react"
import styled from "@emotion/styled"
import Img from "gatsby-image"

import ContentBase from "./ContentBase"
import { ButtonLink } from "./Button"
import { Subhead as SubheadBase } from "./Typography"

import chickenWire from "../images/chicken_wire.png"

const Container = styled.div`
  padding-top: ${p => p.theme.nav.height}px;
  position: relative;
`
const Content = styled(ContentBase)`
  background: url(${chickenWire}) no-repeat 50% 50%;
  background-size: 985px 691px;
  display: flex;
  padding-top: 14.4%;
  padding-bottom: 13.1%;
  text-align: center;
`

const Headline = styled.h1`
  font-family: ${p => p.theme.fonts.headline};
  line-height: 1.52;
  margin-left: auto;
  margin-right: auto;
  ${p =>
    p.theme.mq({
      fontSize: ["24px", "24px", "36px", "48px"],
      width: ["100%", "100%", "94%", "88%"],
    })}
`

const Subhead = styled(SubheadBase)`
  ${p =>
    p.theme.mq({
      width: ["100%", "100%", "75.6%", "53%"],
    })}
`

const Text = styled.div`
  top: ${p => p.theme.nav.height}px;
  left: calc(50% - ${p => p.theme.content.maxWidth / 2}px);
  width: ${p => p.theme.content.maxWidth}px;
`

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    h1: Headline,
    p: Subhead,
  },
}).Compiler

const HeroText = ({ htmlAst, image, link, href }) => {
  return (
    <Container>
      <Content>
        {!!image && <Img fluid={image.childImageSharp.fluid} />}
        <Text>
          {renderAst(htmlAst)}
          {!!link && !!href && (
            <ButtonLink to={href} mode="color">
              {link}
            </ButtonLink>
          )}
        </Text>
      </Content>
    </Container>
  )
}

export { HeroText }
