import React from "react"

const TwitterIcon = ({ color = "#fff", width = "26px", height = "26px" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 26 21"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(0.000000, -2.000000)" fill={color}>
        <path
          d="M25.71,4.47964467 C24.9813288,5.54546218 24.1004113,6.45356858 23.0672208,7.20399112 C23.0780965,7.35625076 23.0835343,7.5846368 23.0835343,7.88915609 C23.0835343,9.30299565 22.8768993,10.7140951 22.4636231,12.1224968 C22.0503469,13.5308985 21.4222853,14.8821826 20.5794194,16.1763896 C19.7365535,17.4705966 18.7332863,18.6152457 17.5695876,19.6103712 C16.4058889,20.6054967 15.002946,21.3994101 13.360717,21.9921352 C11.718488,22.5848602 9.96209057,22.8812183 8.09147208,22.8812183 C5.14416039,22.8812183 2.44703,22.0927427 0,20.5157678 C0.380649111,20.5592705 0.804794616,20.5810216 1.27244924,20.5810216 C3.71947924,20.5810216 5.90002216,19.8306103 7.8141434,18.3297652 C6.67219607,18.3080139 5.64989665,17.9572781 4.74721447,17.2775476 C3.84453229,16.597817 3.22462732,15.7304939 2.88748096,14.675552 C3.2463787,14.7299305 3.57808223,14.7571193 3.88260152,14.7571193 C4.35025615,14.7571193 4.81246599,14.6973039 5.26924492,14.5776713 C4.05116777,14.3275305 3.04246275,13.7212199 2.24309962,12.7587214 C1.44373649,11.796223 1.04406091,10.6787627 1.04406091,9.40630711 L1.04406091,9.3410533 C1.78360776,9.75432948 2.57752114,9.97727776 3.42582487,10.0099048 C2.70802941,9.53137451 2.1370643,8.90603178 1.71291244,8.13385787 C1.28876057,7.36168396 1.07668782,6.52426847 1.07668782,5.62158629 C1.07668782,4.66452567 1.31594938,3.77817032 1.7944797,2.96249365 C3.11043805,4.5829713 4.71185922,5.87987775 6.59879124,6.8532519 C8.48572327,7.82662606 10.5058522,8.36768347 12.6592386,8.47644036 C12.5722331,8.06316418 12.528731,7.66076972 12.528731,7.26924492 C12.528731,5.81190261 13.0425996,4.56937379 14.0703522,3.54162119 C15.0981048,2.51386859 16.3406336,2 17.7979759,2 C19.3205723,2 20.6038844,2.55465181 21.6479505,3.66397208 C22.8334006,3.43558261 23.948142,3.01143711 24.9922081,2.39152284 C24.5898076,3.64222707 23.8176453,4.61014886 22.675698,5.29531726 C23.687137,5.18656037 24.6985609,4.91467223 25.71,4.47964467 Z"
          id="icon170---FontAwesome"
        />
      </g>
    </g>
  </svg>
)

export default TwitterIcon
