import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

import Button from "../../Button"

const ButtonWrapper = styled.div`
  margin-top: 10%;
  display: flex;
  justify-content: flex-end;
`

const StyledButton = styled(Button)`
  border-width: 2px;
  ${p =>
    p.sent &&
    css`
      background: green;
      color: white;
      &:hover {
        background: green;
        color: white;
      }
    `}
  ${p =>
    p.error &&
    css`
      background: red;
      color: white;
      &:hover {
        background: red;
        color: white;
      }
    `}
`

const WrappedButton = props => (
  <ButtonWrapper>
    <StyledButton {...props} />
  </ButtonWrapper>
)

export { WrappedButton as Button, ButtonWrapper }
