import React from "react"
import styled from "@emotion/styled"

import { ButtonLink } from "./Button"
import { Headline, Subhead } from "./Typography"

const Card = styled.div`
  margin: 0 2% 9%;
  width: 46%;
  ${p =>
    p.theme.mq({
      width: ["100%", "100%", "46%", "46%"],
    })}
`

const Title = Headline.withComponent("h3")

const Author = styled.p`
  color: ${p => p.theme.colors.primary};
  font-family: ${p => p.theme.fonts.copy};
  line-height: 1.88;
  letter-spacing: 2.6px;
  text-transform: uppercase;
`

const Entry = ({ title, summary, fields: { slug }, author, entry }) => {
  return (
    <Card>
      {!!author && entry === "blog" && <Author>{author}</Author>}
      <Title>{title}</Title>
      <Subhead>{summary}</Subhead>
      <ButtonLink to={slug}>Info</ButtonLink>
    </Card>
  )
}

export default Entry
