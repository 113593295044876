import React from "react"
import rehypeReact from "rehype-react"
import styled from "@emotion/styled"
import ReactPlayer from "react-player/lib/players/FilePlayer"
import { isMobile } from "react-device-detect"

import ContentBase from "./ContentBase"
import { ButtonLink } from "./Button"
import GatsbyImage from "gatsby-image"
import { Headline } from "./Typography"
import TextLoop from "./TextLoop"

const Container = styled.div`
  position: relative;
  .loadButton-appear {
  opacity: 0;
}
 
.loadButton-appear.loadButton-appear-active{
  animation-name: fadeIn;
  animation-timing-function: ease-out;
  animation-duration: 0.8s;
  animation-delay: 3s;
  //animation-fill-mode: forwards;
}

.hero-appear {
  opacity: 0;
}
 
.hero-appear.hero-appear-active{
  animation-name: fadeIn;
  animation-timing-function: ease-out;
  animation-duration: 0.8s;
  animation-delay: 0.8s;
  //animation-fill-mode: forwards;
}

.projects-appear {
  opacity: 0;
  transform: translateY(50px);
}

.projects-appear.projects-appear-active{
  animation-name: fromBelowEasy;
  animation-timing-function: ease-out;
  animation-duration: 0.8s;
  animation-delay: 2s;
  //animation-fill-mode: forwards;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fromBelowEasy {
  from {
    opacity: 0;
    transform: translateY(50px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
  ${p =>
    p.theme.mq({
      height: ["100vh", "100vh", "100vh", "auto"],
    })}
  ${"" /* &:after {
    border-color: white;
    border-style: solid;
    border-width: 4px 4px 0 0;
    content: "";
    display: inline-block;
    height: 40px;
    left: 50%;
    position: relative;
    vertical-align: top;
    width: 40px;
    margin-left: -40px;
    bottom: 50px;
    transform: rotate(135deg);
  }
  &:hover {
    &::after {
      color: black;
    }
  } */}
`

const Content = styled(ContentBase)`
  position: absolute;
  margin: 0;
  height: 100%;
  width: 100%;
  top: 0;
  ${p =>
    p.theme.mq({
      left: ["0", "0", "0", "50%"],
      transform: ["none", "none", "none", "translateX(-50%)"],
    })}
`

const Video = styled(ReactPlayer)`
  top: 0;
  left: 0;
  height: 100% !important;
  width: 100% !important;
  & > video {
    object-fit: cover;
    ${p =>
      p.theme.mq({
        height: [
          "100% !important",
          "100% !important",
          "100% !important",
          "auto !important",
        ],
        width: [
          "100% !important",
          "100% !important",
          "100% !important",
          "100% !important",
        ],
      })}
  }
  `

const Image = styled(GatsbyImage)`
  height: 100%;
  width: 100%;
`

const Text = styled.div`
  color: #fff;
  position: absolute;
  transform: translateY(-50%);
  ${p =>
    p.theme.mq({
      left: ["32px", "32px", "40px", "40px"],
      top: ["45%", "45%", "40%", "40%"],
      width: ["calc(100% - 64px)", "calc(100% - 64px)", "77%", "77%"],
    })};
`


const SubText = styled.div`
  color: #fff;
  position: absolute;
  transform: translateY(-50%);
  ${p =>
    p.theme.mq({
      left: ["18px", "18px", "20px", "22px"],
      top: ["55%", "55%", "50%", "50%"],
      width: ["calc(100% - 64px)", "calc(100% - 64px)", "77%", "77%"],
    })};
`


const Heading = styled.h1`
  font-family: ${p => p.theme.fonts.headline};
  text-shadow: 1px 1px 0 #000;
  ${p => p.theme.mq({ fontSize: ["24px", "24px", "44px", "64px"] })}
`

const Subhead = styled.p`
  font-family: ${p => p.theme.fonts.copy};
  line-height: 1.42;
  margin-bottom: 60px;
  ${p =>
    p.theme.mq({
      fontSize: ["16px", "16px", "20px", "24px"],
      width: ["100%", "100%", "62%", "62%"],
    })}
`

const StyledTextDiv = styled.div`
  margin-top: 50px;
  ${p => p.theme.mq({ fontSize: ["13px", "13px", "16px", "18px"] })}
  font-family: ${p => p.theme.fonts.copy};
`

const ImageContainer = styled.div`
  overflow: hidden;
  position: relative;
  ${p =>
    p.theme.mq({
      height: ["100%", "100%", "100%", "auto"],
      width: ["100%", "100%", "100%", "auto"],
      paddingBottom: [0, 0, 0, "56.2%"],
    })}
  & > div {
    position: absolute !important;
  }
`

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    h1: Heading,
    h2: Headline,
    p: Subhead,
  },
}).Compiler

const HeroMedia = ({ htmlAst, video, link, href, poster, image }) => {
  const config = {}

  if (poster) {
    config.file = {
      attributes: {
        poster: poster.publicURL,
      },
    }
  }

  return (
    <Container>
      {image && (
        <ImageContainer>
          <Image fluid={image.childImageSharp.fluid} alt="" />
        </ImageContainer>
      )}
      {video ? (
        !isMobile ? (
          <Video
            url={video.publicURL}
            config={config}
            width="100%"
            height="auto"
            muted={true}
            playing
            loop
          />
        ) : (
          <Image fluid={poster.childImageSharp.fluid} alt="poster" />
        )
      ) : null}
      <Content>
        <Text>
          {renderAst(htmlAst)}
          <Subhead>A team of API and GraphQL experts, that have built solutions at scale, for some of the world's largest companies.</Subhead>
          <StyledTextDiv>
            <TextLoop
              interval={6000}
              mask={true}
              fade={true}
              noWrap={false}
              springConfig={{ stiffness: 50, damping: 65 }}
            >
              <div>
                They’ll have to pry Crucial from my cold dead hands!<b><br /><br />Andrew McGrath, Head of DX, Refinitiv</b>
              </div>
              <div>
                If you need a tech adrenaline shot that ends up having a lasting effect, I highly recommend these group of guys! Brilliant, professional, and get shit done!<b><br /><br />Hassan Bazzi, CTO, Kitab Satwi
                </b>
              </div>
              <div>
                Crucial is doing AMAZING!  We are so impressed and thankful for the value they provide to our teams<b> <br /><br />Kate Wardin, UI Manager, Target
                </b>
              </div>
              <div>
                I’m excited to have their kind of talent in the house!<b><br /><br />Martin Stampe, Digital Bank CIO, Danske
                </b>
              </div>
              <div>
                We have been really pleased by working with you. We see you as highly valuable and skilled consultants than can make a difference for us<b><br /><br />Jan Baungaard, Sr. Development Director, Danske
                </b></div>
            </TextLoop>
          </StyledTextDiv>
        </Text>
      </Content>
    </Container>
  )
}

export { HeroMedia }
