import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"
import rehypeReact from "rehype-react"

import { ContactUsForm } from "./Form"
import ContentBase from "./ContentBase"
import { Headline as HeadlineBase, Subhead as SubheadBase } from "./Typography"
import { parseSections } from "../utils"
import { FullTwoColumnColumns, FullTwoColumnContent } from "./FullTwoColumn"

const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/contact-common.md/" } }
    ) {
      ...SectionFragment
    }
  }
`

const Container = styled.div`
  background: rgba(26, 11, 64, 0.05);
  ${p =>
    p.theme.mq({
      paddingTop: ["16%", "10%", "8%", "6%"],
      paddingBottom: ["16%", "10%", "8%", "6%"],
    })}
`
const Content = styled(ContentBase)`
  display: flex;
  > div {
    flex: 1 1 0;
  }

  & ${FullTwoColumnContent} {
    padding: 0;
  }

  & ${FullTwoColumnColumns} > div {
    justify-content: flex-start;

    & > div {
      margin-left: 0;

      ${p =>
        p.theme.mq({
          marginBottom: ["20px", "20px", "inherit", "inherit"],
        })}
    }
  }

  ${p =>
    p.theme.mq({
      flexDirection: ["column", "column", "row", "row"],
    })}
`

const Headline = styled(HeadlineBase)`
  ${p =>
    p.theme.mq({
      fontSize: ["2.5rem", "2.5rem", "5rem", "5rem"],
    })}
`

const Subhead = styled(SubheadBase)`
  ${p =>
    p.theme.mq({
      marginBottom: ["20px", "50px", "50px", "50px"],
    })}
`

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    h2: Headline,
    p: Subhead,
  },
}).Compiler

const Contact = () => {
  return (
    <StaticQuery query={query}>
      {({ allMarkdownRemark }) => {
        if (!(allMarkdownRemark && allMarkdownRemark.edges)) {
          return null
        }

        const { node } = allMarkdownRemark.edges[0]
        const { htmlAst } = node
        const sectionData = {}

        if (allMarkdownRemark.edges.length > 1) {
          sectionData.allMarkdownRemark = {
            edges: allMarkdownRemark.edges.slice(1),
          }
        }

        return (
          <Container id="contact">
            <Content>
              <div>
                {renderAst(htmlAst)}
                {parseSections(sectionData)}
              </div>
              <ContactUsForm />
            </Content>
          </Container>
        )
      }}
    </StaticQuery>
  )
}

export default Contact
