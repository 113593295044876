import React from "react"
import { Link as GatsbyLink } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

const getColor = p =>
  p.mode === "light"
    ? "#fff"
    : p.mode === "color"
    ? p.theme.colors.primary
    : p.theme.colors.text

const ButtonCSS = p => {
  const { arrow = true } = p
  return css`
    align-items: center;
    background: transparent;
    border: 1px solid ${getColor(p)};
    color: ${getColor(p)};
    display: inline-flex;
    font-family: ${p.theme.fonts.copy};
    justify-content: center;
    line-height: 50px;
    padding: 0 20px;
    cursor: pointer;
    text-decoration: none;
    text-transform: uppercase;
    transition: 300ms background, 300ms color;
    min-width: 175px;
    ${arrow &&
      css`
        &:after {
          content: "";
          height: 8px;
          width: 8px;
          border-top: 1px solid ${getColor(p)};
          border-right: 1px solid ${getColor(p)};
          transform: rotate(45deg);
          margin-left: 8px;
          transition: 300ms border-color;
        }
      `}
    &:hover {
      background: ${p.mode === "color" ? "#fff" : p.theme.colors.primary};
      color: ${p.mode === "color" ? p.theme.colors.primary : "#fff"};
      &:after {
        border-color: ${p.mode === "color" ? p.theme.colors.primary : "#fff"};
      }
    }
    ${p.theme.mq({
      fontSize: ["12px", "12px", "14px", "16px"],
    })}
  `
}

const StyledButton = styled.button`
  ${ButtonCSS}
`

const StyledLink = styled(GatsbyLink)`
  ${ButtonCSS}
`

const StyledAnchor = StyledButton.withComponent("a")

// Since DOM elements <a> cannot receive activeClassName,
// destructure the prop here and pass it only to GatsbyLink
const ButtonLink = ({ children, to, activeClassName, ...other }) => {
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(to)

  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <StyledLink to={to} activeClassName={activeClassName} {...other}>
        {children}
      </StyledLink>
    )
  }
  return (
    <StyledAnchor href={to} {...other}>
      {children}
    </StyledAnchor>
  )
}

export default StyledButton
export { ButtonLink }
