import React from "react"
import { graphql } from "gatsby"
import * as Components from "../components"
import VerticalSpacing from "../components/VerticalSpacing"

export const SectionFragment = graphql`
  fragment SectionFragment on MarkdownRemarkConnection {
    edges {
      node {
        id
        html
        htmlAst
        fields {
          slug
        }
        frontmatter {
          border
          navTheme
          title
          component
          heading
          link
          href
          props
          type
          spacing
          anchor
          image {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1168) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          images {
            id
            publicURL
            childImageSharp {
              fluid(maxWidth: 1168) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          poster {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1168) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          video {
            publicURL
          }
        }
      }
    }
  }
`

export const parseSections = ({ allMarkdownRemark }) => {
  if (!(allMarkdownRemark && allMarkdownRemark.edges)) {
    return null
  }

  return allMarkdownRemark.edges.map(({ node }) => {
    const { id, html, frontmatter, ...restNode } = node
    const { component, props, spacing, ...restFrontmatter } = frontmatter
    const Component = Components[component]
    const componentProps = props ? JSON.parse(props) : {}

    if (!Component) {
      return null
    }

    return (
      <React.Fragment key={id}>
        <Component {...restNode} {...restFrontmatter} {...componentProps}>
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </Component>
        {spacing && <VerticalSpacing size={spacing} />}
      </React.Fragment>
    )
  })
}
