import React from "react"
import rehypeReact from "rehype-react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import Img from "gatsby-image"

import { default as Content } from "./ContentBase"
import { Headline as HeadlineBase, Subhead } from "./Typography"
import { ButtonLink } from "./Button"

const regular = props =>
  css`
    flex-direction: row;
    & > div {
      ${props.theme.mq({
        width: ["100%", "100%", "40%", "40%"],
      })}
    }
    & > div:first-of-type {
      ${props.theme.mq({
        marginRight: ["0", "0", "10%", "10%"],
        width: ["100%", "100%", "50%", "50%"],
      })}
    }
  `

const Columns = styled.div`
  display: flex;
  justify-content: center;
  ${p =>
    p.theme.mq({
      flexWrap: ["wrap", "wrap", "nowrap", "nowrap"],
    })}
  ${regular};
`

const Image = styled.img`
  margin: 0;
`

const ASTWrapper = styled.div`
  h2 {
    padding-top: 21%;
  }
`
const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    h2: HeadlineBase,
    p: Subhead,
  },
}).Compiler

const HalfHeadingHalfImage = ({ htmlAst, image, inverted, link, href }) => {
  return (
    <Content>
      <Columns inverted={inverted}>
        <ASTWrapper>
          {renderAst(htmlAst)}
          {!!link && !!href && <ButtonLink to={href}>{link}</ButtonLink>}
        </ASTWrapper>
        <div>
          {!!image ? (
            image.publicURL.endsWith(".svg") ? (
              <Image src={image.publicURL} alt="" />
            ) : (
              <Img fluid={image.childImageSharp.fluid} />
            )
          ) : null}
        </div>
      </Columns>
    </Content>
  )
}

export { HalfHeadingHalfImage }
