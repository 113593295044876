import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"

import { default as Content } from "./ContentBase"
import Entry from "./Entry"

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -2%;
`

export const query = graphql`
  {
    allMarkdownRemark(
      filter: { frontmatter: { entry: { regex: "/career|our-work|blog/" } } }
    ) {
      edges {
        node {
          id
          html
          htmlAst
          fields {
            slug
          }
          frontmatter {
            author
            anchor
            date
            route
            summary
            title
            entry
          }
        }
      }
    }
  }
`

const EntriesGrid = ({ type, anchor }) => {
  if (!/career|our-work|blog/.test(type)) {
    return null
  }

  return (
    <StaticQuery query={query}>
      {({ allMarkdownRemark }) => {
        if (!(allMarkdownRemark && allMarkdownRemark.edges)) {
          return null
        }

        return (
          <Content>
            <Grid id={anchor}>
              {allMarkdownRemark.edges
                .filter(
                  ({
                    node: {
                      frontmatter: { entry },
                    },
                  }) => entry === type
                )
                .map(({ node }) => {
                  const { id, html, frontmatter, ...restNode } = node
                  return (
                    <Entry key={id} {...restNode} {...frontmatter}>
                      <div dangerouslySetInnerHTML={{ __html: html }} />
                    </Entry>
                  )
                })}
            </Grid>
          </Content>
        )
      }}
    </StaticQuery>
  )
}

export { EntriesGrid }
