import React from "react"

const FacebookIcon = ({ color = "#fff", width = "26px", height = "26px" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 26 26"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-94.000000, 0.000000)" fill={color}>
        <path
          d="M114.892857,0 C116.220989,0 117.35658,0.471535463 118.299665,1.41462054 C119.24275,2.35770561 119.714286,3.49329693 119.714286,4.82142857 L119.714286,20.8928571 C119.714286,22.2209888 119.24275,23.3565801 118.299665,24.2996652 C117.35658,25.2427503 116.220989,25.7142857 114.892857,25.7142857 L111.745536,25.7142857 L111.745536,15.7533482 L115.077009,15.7533482 L115.579241,11.8694196 L111.745536,11.8694196 L111.745536,9.39174107 C111.745536,8.76673795 111.876673,8.29799263 112.138951,7.98549107 C112.401229,7.67298951 112.911827,7.51674107 113.670759,7.51674107 L115.71317,7.5 L115.71317,4.03459821 C115.010041,3.93415128 114.016747,3.88392857 112.733259,3.88392857 C111.215394,3.88392857 110.001679,4.33035268 109.092076,5.22321429 C108.182473,6.11607589 107.727679,7.377224 107.727679,9.00669643 L107.727679,11.8694196 L104.379464,11.8694196 L104.379464,15.7533482 L107.727679,15.7533482 L107.727679,25.7142857 L98.8214286,25.7142857 C97.4932969,25.7142857 96.3577056,25.2427503 95.4146205,24.2996652 C94.4715355,23.3565801 94,22.2209888 94,20.8928571 L94,4.82142857 C94,3.49329693 94.4715355,2.35770561 95.4146205,1.41462054 C96.3577056,0.471535463 97.4932969,0 98.8214286,0 L114.892857,0 Z"
          id="icon148---FontAwesome"
        />
      </g>
    </g>
  </svg>
)

export default FacebookIcon
